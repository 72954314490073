<template>
  <b-form @submit.prevent="$emit('processCliente', cliente)">
    <!-- Cliente Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Rut -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Rut"
            label-for="rut"
          >
            <b-form-input
              id="rut"
              v-model="cliente.rut"
              :state="!$v.cliente.rut.$invalid"
              placeholder="Introduce el rut"
              @input="$v.cliente.$touch"
            />
            <b-form-invalid-feedback
              v-if="$v.cliente.$dirty"
              id="rutInfo"
            >
              El campo rut
              <span v-if="!$v.cliente.rut.required"> es requerido.</span>
              <span v-if="!$v.cliente.rut.maxLength"> debe tener max 50 caracteres.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Dirección -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Dirección"
            label-for="direccion"
          >
            <b-form-input
              id="direccion"
              v-model="cliente.direccion"
              :state="!$v.cliente.direccion.$invalid"
              placeholder="Introduce la dirección"
              @input="$v.cliente.$touch"
            />
            <b-form-invalid-feedback
              v-if="$v.cliente.$dirty"
              id="direccionInfo"
            >
              El campo dirección
              <span v-if="!$v.cliente.direccion.required"> es requerido.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Razón Social -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Razón Social"
            label-for="razonSocial"
          >
            <b-form-input
              id="razonSocial"
              v-model="cliente.razonSocial"
              :state="!$v.cliente.razonSocial.$invalid"
              placeholder="Introduce la razón social"
              @input="$v.cliente.$touch"
            />
            <b-form-invalid-feedback
              v-if="$v.cliente.$dirty"
              id="razonSocialInfo"
            >
              El campo razón social
              <span v-if="!$v.cliente.razonSocial.required"> es requerido.</span>
              <span v-if="!$v.cliente.razonSocial.maxLength"> debe tener max 200 caracteres.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Nombre -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Nombre"
            label-for="nombre"
          >
            <b-form-input
              id="nombre"
              v-model="cliente.nombre"
              :state="!$v.cliente.nombre.$invalid"
              placeholder="Introduce el nombre"
              @input="$v.cliente.$touch"
            />
            <b-form-invalid-feedback
              v-if="$v.cliente.$dirty"
              id="nombreInfo"
            >
              El campo nombre
              <span v-if="!$v.cliente.nombre.required"> es requerido.</span>
              <span v-if="!$v.cliente.nombre.maxLength"> debe tener max 80 caracteres.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Telefono -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Teléfono"
            label-for="telefono"
          >
            <b-form-input
              id="telefono"
              v-model="telefono"
              :state="!$v.cliente.telefono.$invalid"
              placeholder="Introduce el teléfono"
              @input="$v.cliente.$touch"
              @keyup="formatearTelefono"
              @blur="formatearTelefono"
            />
            <b-form-invalid-feedback
              v-if="$v.cliente.$dirty"
              id="telefonoInfo"
            >
              El campo teléfono
              <span v-if="!$v.cliente.telefono.required"> es requerido.</span>
              <span v-if="!$v.cliente.telefono.maxLength"> debe tener max 25 caracteres.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Correo -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Correo"
            label-for="correo"
          >
            <b-form-input
              id="correo"
              v-model="cliente.correo"
              :state="!$v.cliente.correo.$invalid"
              type="email"
              placeholder="Introduce el correo"
              @input="$v.cliente.$touch"
            />
            <b-form-invalid-feedback
              v-if="$v.cliente.$dirty"
              id="correoInfo"
            >
              El campo correo
              <span v-if="!$v.cliente.correo.required"> es requerido.</span>
              <span v-if="!$v.cliente.correo.maxLength"> debe tener max 80 caracteres.</span>
              <span v-if="!$v.cliente.correo.email"> debe ser un correo valido.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <btnSubmit
      :btnDisabled="$v.cliente.$invalid"
      :btnText="clienteSubmit"
    />

  </b-form>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { validationMixin } from 'vuelidate'
import {
  required, maxLength, email,
} from 'vuelidate/lib/validators'
import { formatearTelefono } from '@/mixins/forms'

const btnSubmit = () => import('@/layouts/components/Recycled/Form/btnSubmit.vue')

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    // vSelect,
    btnSubmit,
  },
  mixins: [validationMixin, formatearTelefono],
  props: {
    cliente: {
      type: Object,
      required: true,
    },
    clienteSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Cliente',
    },
  },
  data() {
    return {
      telefono: this.cliente.telefono,
    }
  },
  watch: {
    telefono() {
      this.cliente.telefono = this.telefono
    },
  },
  validations() {
    return {
      cliente: {
        rut: {
          required,
          maxLength: maxLength(50),
        },
        direccion: {
          required,
        },
        razonSocial: {
          required,
          maxLength: maxLength(200),
        },
        nombre: {
          required,
          maxLength: maxLength(80),
        },
        telefono: {
          required,
          maxLength: maxLength(25),
        },
        correo: {
          required,
          maxLength: maxLength(80),
          email,
        },
        estado: {},
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
